/** @jsx jsx */
import { jsx, Flex } from "theme-ui"
import Seo from "../components/seo"

const NotFoundPage = () => (
  <div>
    <Seo title="404: Not found" />
    <Flex sx={{
      justifyContent: "center",
      flexDirection: 'column',
      alignItems: 'center',
      padding: '1rem 1rem .5rem 1rem'
    }}>
    <h1>NOT FOUND</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </Flex>
  </div>
)

export default NotFoundPage
